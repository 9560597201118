import { http, createConfig } from 'wagmi'
import { bahamut } from 'wagmi/chains'
import { walletConnect } from 'wagmi/connectors'
import logo from "../../assets/images/logos/cerebrum.svg";

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig
  }
}

const projectId = 'f24ade6d5f8d25444a0d9edda48fd027'

const FastexWalletId = '6db5c2cd78ea5a09e820b7543dacc90bf3b1727e5bbaddff544b301de1f74f39';
const MetamaskWalletId = 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96';
const TrustWalletId = '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0';

export const wagmiConfig = createConfig({
  chains: [bahamut],
  connectors: [
    walletConnect({
      projectId,
      qrModalOptions: {
        explorerRecommendedWalletIds: [FastexWalletId, MetamaskWalletId, TrustWalletId],
        mobileWallets: [
          {
            id: "1",
            name: "Fastex Wallet",
            links: {
                native: "string"
            }
          }
        ],
      },
      metadata: {
        name: 'Cerebrum',
        description: 'Cerebrum Meta',
        url: window.location.origin,
        icons: [logo],
      },
    }),
  ],
  multiInjectedProviderDiscovery: true,
  transports: {
    [bahamut.id]: http(),
  },
});

export const authDefaultState = {
  WalletConnect: {
      error: "",
      pending: false,
  },
  MetaMask: {
      error: "",
      pending: false,
  }
}