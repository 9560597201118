const APPID = "51d8376d2f4d4228a9821b3d9cc0f54a";
const MEDIA_DEVICE_STATUS = {
    enabled: "enabled",
    disabled: "disabled",
    notFound: "notFound",
};

const ICON_TYPES = {
    mic: "mic",
    call: "call", 
    hoory: "hoory"
}

const MICROPHONE_STATUS = {
    0: "MUTE",
    1: "UNMUTE",
    2: "FREEZE",
    3: "MUTE_ALL",
};

const SELECT_OPTION = [
    {
        value: 2,
        visibleMinutes: 2,
        translationName: "minutes"
    },
    {
        value: 10,
        visibleMinutes: 10,
        translationName: "minutes"
    },
    {
        value: 30,
        visibleMinutes: 30,
        translationName: "minutes"
    },
    {
        value: 60,
        visibleMinutes: 1,
        translationName: "hours"
    },
    {
        value: 1440,
        visibleMinutes: 24,
        translationName: "hours"
    }
];

const TICKET_ERRORS = {
    used_ticket: "This ticket has already been used.",
    invalid_ticket: "This ticket is invalid.",
    invalid_qr: "This qr is invalid"
  } as const;
  
type TicketErrorKeys = keyof typeof TICKET_ERRORS;

interface TranslatableError extends Error {
    translation_key?: TicketErrorKeys;
}

export const CALL_STATUS = {
    0: 'None',
    1: 'Invited',
    2: 'Connected',
    3: 'Accepted',
    4: 'Rejected',
} as const;

export enum STATUS {
    None = 0,
    Invited = 1,
    Connected = 2,
    Accepted = 3,
    Rejected = 4,
}

export enum GlobalFilter {
    popular = "popular",
    last = "last",
}

export type GlobalFilterStatus = keyof typeof GlobalFilter;


export type CallStatus = keyof typeof CALL_STATUS;

export {APPID, MEDIA_DEVICE_STATUS, ICON_TYPES, MICROPHONE_STATUS, SELECT_OPTION, TICKET_ERRORS, TranslatableError};

export const builds = {
    spaces: "restaurant1",
    mobile: "mobile1",
    copia: "desktop3",
}