import { makeAutoObservable } from 'mobx';
import { cacheService } from '../../services/cacheService';
import { apiServiceV2 } from '../../services/apiService';
import { UnityLayout } from '../../modules/unityLayout/unityLayout.type';
import { TTabOptionsList, TUserOptionsList } from './participants.type';
import { privateTalkStore } from '../../modules/scenetemplate/stores/privateTalk.store';
import { CALL_STATUS, CallStatus, STATUS } from '../../modules/scenetemplate/hooks/constants';
import { sendMessageStore } from "../../modules/unityLayout/sendMessage.store";
import { unityProviderStore } from '../UnityLoader/unity.provider.store';

class ParticipantsStore {
  isLoading: boolean = false;
  activeTab: number = 0;
  user = {} as UnityLayout.RoomUsersState;
  userJoinRoom = false;
  roomCreatorId = null;
  kickOffModal = false;
  blockedUsersList: any = [];
  adminUsers: any = [];
  userIsBlocked = false;
  makeAdmin = false;
  showExportParticipantsList = false;
  popupToggle: boolean = false;
  tabOptionsList: TTabOptionsList[];
  userOptions: TUserOptionsList[];
  popUpStatus: boolean = false;
  modalContent: any = {};

  constructor() {
    makeAutoObservable(this);
    this.tabOptionsList = [
      {
        name: () => 'Black list',
        hidden: () => false,
        action: () => this.setActiveTab(1),
      },
      {
        name: () => 'Admin list',
        hidden: () => false,
        action: () => this.setActiveTab(2),
      },
      // {
      //   name: () => 'Export Participants list',
      //   hidden: () => false,
      //   action: () => this.setActiveTab(3),
      // }
    ];

    this.userOptions = [
      {
        name: () => this.user?.freezedValue ? 'Unrestrict' : 'Restrict',
        hidden: (creator, isAdmin) => creator ? false : isAdmin ? !!(this.user.isAdmin || this.user.isCreator) : true,
        action: () => this.setModal(true, this.user?.freezedValue ? 'unfreeze' : 'freeze'),
      },
      {
        name: () => this.user?.isAdmin ? "Make User" : "Make Admin",
        hidden: (creator) => !creator,
        action: () => this.setModal(true, this.user?.isAdmin ? 'remove_admin' : 'admin'),
      },
      {
        name: () => 'Kick off',
        kickOff: true,
        hidden: (creator, isAdmin) => creator ? false : isAdmin ? !!(this.user.isAdmin || this.user.isCreator) : true,
        action: () => this.kickOffModal = true,
      },
      {
        name: () => 'Block',
        block: true,
        hidden: (creator, isAdmin) => creator ? false : isAdmin ? !!(this.user.isAdmin || this.user.isCreator) : true,
        action: () => this.setModal(true, 'block'),
      },
      {
        name: () => this.user.privateCallState === STATUS.None ? 'Invite to private talk' : CALL_STATUS[this.user.privateCallState as CallStatus],
        hidden: () => false,
        action: () => privateTalkStore.sendInviteFromList({ public_id: this.user.publicId, username: this.user.username }),
      }
    ];
  }

  get isAdminRole() {
    const id = cacheService.getMyId
    return !!this.adminUsers?.filter((user: any) => user.id == id).length
  }


  userIsAdmin = (id: number) => {
    return !!this.adminUsers?.filter((user: any) => user.id == id).length
  }

  setAdminUsers = (users:any)=> {
    this.adminUsers = users;
  }

  removeAdminRole = async (
    user: UnityLayout.RoomUsersState
  ) => {
    try {
      await apiServiceV2.removeAdminRole(user?.publicId);
      sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
        command: "10",
        data: {
          "admins": [
            {
              "public_id": Number(user.publicId),
              "username": user.username
            }
          ],
          "userId": Number(user.publicId),
          "email": user?.email,
          "username": user.username,
          "roomId": Number(unityProviderStore.roomData.id),
          "IsAdmin": false,
        }
      }))
    } catch (e: any) {
      console.warn("ERROR: ", e.message);
    }
  };

  removeAdmin = (id: number) => {
    const res = this.adminUsers.filter(
      (user: any) => user.public_id !== id
    );
    this.adminUsers = res;
  };

  setActiveTab = (num: number) => {
    this.activeTab = num;
  }

  setUser = (user: UnityLayout.RoomUsersState) => {
    this.user = user;
  }

  setModal = (status: boolean, type?: string) => {
    this.popUpStatus = status;
    if (status && type) {
      const allModalContents: any = {
        freeze: {
          button: {
            color: "primary",
            text: "Mute"
          },
          headerTitle: 'Mute the member',
          title: `Mute ${this.user?.username}`,
          subTitle: `You’re about to mute ${this.user.username}. They will not be able to communicate`,
          action: (id: string) => { unityProviderStore.handleFreeze(this.user, Number(id)); this.setModal(false) }
        },
        unfreeze: {
          button: {
            color: "primary",
            text: "Unmute"
          },
          headerTitle: 'Unmute the member',
          title: `Unmute ${this.user?.username}`,
          subTitle: `You’re about to unmute ${this.user.username}. They will be able to communicate`,
          action: (id: string) => { unityProviderStore.handleFreeze(this.user, Number(id)); this.setModal(false) }
        },
        admin: {
          button: {
            color: "primary",
            text: "Make admin"
          },
          headerTitle: 'Make admin',
          title: `Make admin`,
          subTitle: `Are you sure you want to make this user admin ?`,
          action: () => {
            unityProviderStore.makeAdminHandler(this.user);
            this.user.isAdmin = true
            this.setModal(false)
          }
        },
        remove_admin: {
          button: {
            color: "primary",
            text: "Remove admin"
          },
          headerTitle: 'Remove from admin',
          title: `Remove from admin`,
          subTitle: `Are you sure you want to remove this user from admin list?`,
          action: () => {
            this.removeAdminRole(this.user);
            this.user.isAdmin = false
            this.setModal(false)
          }
        },
        block: {
          button: {
            color: "primary",
            text: "Block"
          },
          headerTitle: 'Block',
          title: `Block ${this.user.username}`,
          subTitle: `In case you block ${this.user.username}, they will no longer access this room until you unblock them.`,
          action: (id: string) => { unityProviderStore.handleBlockUser(this.user, id); this.setModal(false) }
        }
      }
      this.modalContent = allModalContents[type]
    }
  }

  popupToggleHandler = () => {
    const flag = !this.popupToggle;
    this.popupToggle = flag;
    if (!flag) {
      this.activeTab = 0;
      sendMessageStore.unLockKeyboardInput();
      unityProviderStore.searchHandler("");
    }
  }


  kickOffModalToggle = (bool: boolean) => {
    this.kickOffModal = bool;
  }


  setBlockedUser = (bool: boolean) => {
    this.userIsBlocked = bool
  }

  handleKickOff = async (duration: any, roomId: string | undefined) => {
    try {
      const response = await apiServiceV2.kickOffUser({ userIds: [this.user?.publicId], duration: Number(duration) }, roomId);
      this.kickOffModal = false;
      if (!response.error) {
        sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
          command: "6",
          data: {
            "user_public_id": this.user.publicId,
            "room_public_id": roomId,
            "message": `You are Kicked Off. You have been kicked for ${duration !== 60 ? duration + " minutes" : "1 hours"}`
          }
        }))
      } else {
        throw response;
      }
    } catch (e: any) {
      console.warn('ERROR: ', e.message);
    }
  }

  setMakeAdmin = (user: any) => {
    if (user) {
      this.user = user
      this.makeAdmin = true;
    } else {
      this.makeAdmin = false;
    }
  }

  setRoomCreatorId = (id: any) => {
    this.roomCreatorId = id;
  }

  userRoleIsCreator = (id: any) => {
    return this.roomCreatorId == id
  }

  isModerator = () => {
    const myId = cacheService.getMyId
    const result = this.userRoleIsCreator(myId)
    return result
  }

  setKickOffUser = (userId: any, roomId: any, msg: any) => {
    if (userId === cacheService.getMyId) {
      const message = msg.split("kicked for")[1].trim().split(" ");
      const time = message[1].toLowerCase();
      const value = message[0];
      const options = {
        [time]: value
      }
    }
  }
  setShowExportParticipantsList = (bool: boolean) => {
    this.showExportParticipantsList = bool;
  }
}

export const participantsStore = new ParticipantsStore();




