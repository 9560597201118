import axios, { AxiosError, AxiosInstance } from "axios";
import { CacheService, cacheService } from "./cacheService";
import { CustomAxiosResponse, queryType, RequestHeader } from "../types/global.types";
import { ucraftStore } from "../ucraft.store";
import { globalStore } from "../global.store";
import { unityProviderStore } from "../components/UnityLoader/unity.provider.store";

class ApiService {
    product: string

    constructor(public _axios: AxiosInstance, public _cacheService: CacheService) {
        this._axios = _axios;
        this._cacheService = _cacheService;
        this.product = process.env.REACT_APP_PRODUCT_ID || "";
    }

    _generate_header = (withToken?: boolean): Partial<RequestHeader> => {
        const header: Partial<RequestHeader> = {
            "Access-Control-Allow-Origin": "*",
            product: Number(this.product),
        }

        if (withToken) header.Authorization = this._cacheService.get("Authorization") || `Bearer ${this._cacheService.get("auth_token")}` || ""
        return header
    };
    _request = async (path: string, data?: Object): Promise<CustomAxiosResponse> => {
        const response = await this._axios.request({
            url: path,
            method: "POST",
            ...data
        }).catch((err: AxiosError) => {
            if (err.status === 401) {
                cacheService.clear();
                globalStore.setIsLogin(false);
                globalStore.navigation("/");
                globalStore.setGuestPopupState(true);
            }
            return err.response;
        })
        return response?.data

    }
}

class ApiServiceUsers extends ApiService {
    constructor(public axios: AxiosInstance) {
        super(axios, cacheService)
    }

    setUserUcraftToken = (id: string, data: { ucraft_token: string }) => this._request(`${id}/`, {
        method: "PATCH",
        headers: {
            ...this._generate_header(true),
        },
        data,
    });

}

class ApiServiceRoom extends ApiService {
    constructor(public axios: AxiosInstance) {
        super(axios, cacheService)
    }

    createCustomSpaces = (data: FormData) => this._request("create_room/", {
        method: "POST",
        headers: {
            ...this._generate_header(true)
        },
        data
    });

    getCopiaSpaces = (params: {
        globalFilter: number,
        limit: number,
        offset: number,
    }) => this._request("create_room/", {
        method: "GET",
        params,
        headers: {
            ...this._generate_header(true)
        },
    })

    uploadFileInRoom = (data: FormData, modelType: string) => this._request(`create_room_decoration/${cacheService.get('roomId')}/?model=${modelType}`, {
        method: "POST",
        headers: {
            ...this._generate_header(true),
        },
        data,
    });

    setEcommerceForRoom = (id: string, ecommerce_domain: string | null, ecommerce_id: string | null) =>
        this._request(`${id}/`, {
            method: "PATCH",
            data: {
                ecommerce_domain,
                ecommerce_id,
            },
            headers: {
                ...this._generate_header(true),
            },
        });
}

class ApiServiceBase extends ApiService {
    constructor(public axios: AxiosInstance) {
        super(axios, cacheService)
    }

    searchMap = (params: queryType): Promise<CustomAxiosResponse> => this._request(`map/locations_list_by_name/`, {
        headers: this._generate_header(false),
        params,
        method: "GET"
    });

    getCategoryLocations = (params: queryType): Promise<CustomAxiosResponse> => this._request(`map/locations_list_by_category/`, {
        headers: this._generate_header(false),
        params,
        method: "GET"
    });

    getGiftList = () => this._request("gifts/my_gifts/", {
        headers: {
            ...this._generate_header(true),
        },
        method: "GET",
    });

    getVigogoGiftList = () => this._request("gifts/my_wins/", {
        headers: {
            ...this._generate_header(true),
        },
        method: "GET",
    });

    getNftsData = () => this._request("map/nft_locations_list/", {
        method: "GET",
        headers: {
            ...this._generate_header()
        },
    })

    getFulgurData = (data: { amount: string }): Promise<CustomAxiosResponse> => (
        this._request(`transaction/deposit`, {
            method: "POST",
            headers: {
                ...this._generate_header(true)
            },
            data
        }));

    getTranslation = (language: string): Promise<CustomAxiosResponse> => (
        this._request(`/landing_page/localize/?lang=${language}`, {
            method: "GET",
        })
    );

    connectNft = (data: {
        email: string,
        password: string
    }): Promise<CustomAxiosResponse> => this._request(`ftnft/login/`, {
        method: "POST",
        headers: {
            ...this._generate_header(true)
        },
        data
    })

}

class ApiServiceEcommerce extends ApiService {
    constructor(public axios: AxiosInstance) {
        super(axios, cacheService)
    }


    getEcommerceParameters = () =>
        this._request(`website-parameters/`, {
            method: "GET",
            headers: {
                "http-authorization": cacheService.get('ac_token')
            },
        });

    getProducts = () =>
        this._request(`get-products/`, {
            method: 'GET',
            params: {
                website_domain: ucraftStore.ecommerceData.domain,
                website_id: ucraftStore.ecommerceData.id,
                first: 100,
                page: 1
            },
            headers: {
                "http-authorization": this._cacheService.get("ac_token"),
            },
        });
}

class ApiServiceDefault extends ApiService {
    constructor(public axios: AxiosInstance) {
        super(axios, cacheService)
    }


    getUcraftAccesToken = (token: string) =>
        this._request(`https://sso.ucraft.ai/api/access-token?nonce=${token}`, {
            method: 'GET'
        });


    getUcraftUser = () =>
        this._request("https://sso.ucraft.ai/api/user", {
            headers: { Authorization: "Bearer " + cacheService.get("ac_token") },
            method: 'GET'

        });


    setLogoutUcraft = () =>
        this._request("https://sso.ucraft.ai/api/logout", {
            headers: { Authorization: "Bearer " + cacheService.get("ac_token") }
        });

}

class ApiServiceV2 extends ApiService {
    constructor(public axios: AxiosInstance) {
        super(axios, cacheService)
    }

    generate_header = () => {
        return {
            Authorization: `Bearer ${this._cacheService.get("auth_token")}` || ""
        }
    }

    editUserData = (data: {
        username: string,
        hooryToken: string,
        gender: number,
        birthday: string
    }): Promise<CustomAxiosResponse> => this._request(`dashboard/edit-profile`, {
        method: "POST",
        headers: {
            ...this.generate_header(),
        },
        data,
    });


    login = (login: any) =>
        this._request('customer/login', {
            method: 'POST',
            data: login
        });

    logOut = () =>
        this._request('customer/logout', {
            headers: {
                ...this.generate_header(),
            },
            method: 'POST',
        });

    registration = (regData: any) =>
        this._request('customer/signup', {
            method: 'POST',
            data: regData
        });

    setRegistrationVerifyCode = (userId: string, otpToken: string): Promise<CustomAxiosResponse> => this._request(`customer/verification`, {
        method: "POST",
        data: {
            otpToken,
            userId
        },
    });

    setForgotEmail = (data: {
        email: string
    }): Promise<CustomAxiosResponse> => this._request("customer/password-forgot", {
        method: "POST",
        headers: {
            ...this.generate_header(),
        },
        data,
    });

    setForgotCode = (data: {
        email: string,
        code: string
    }): Promise<CustomAxiosResponse> => this._request("customer/password-re-set", {
        method: "POST",
        headers: { ...this.generate_header() },
        data,
    })

    setNewPassword = (data: {
        email: string,
        newPasswordOne: string,
        newPasswordTwo: string,
    }): Promise<CustomAxiosResponse> => this._request("customer/set-new-password", {
        method: "POST",
        headers: { ...this.generate_header() },
        data,
    })

    getCurrentUser = (): Promise<CustomAxiosResponse> => this._request("current-user", {
        headers: {
            ...this.generate_header(),
        },
        method: 'GET'
    });

    getRooms = (params: queryType): Promise<CustomAxiosResponse> => this._request(`rooms`, {
        headers: {
            ...this.generate_header(),
        },
        params,
        method: "GET"
    });

    getLikedRooms = (params: queryType): Promise<CustomAxiosResponse> => this._request(`users/liked-rooms`, {
        headers: {
            ...this.generate_header(),
        },
        params,
        method: "GET"
    });

    getMySpaces = (params: queryType): Promise<CustomAxiosResponse> => this._request(`dashboard/spaces`, {
        headers: {
            ...this.generate_header(),
        },
        params,
        method: "GET"
    });

    getRoomByID = (id: string) => this._request(`rooms/${id}`, {
        method: "GET",
        headers: {
            ...this.generate_header(),
        },
    });

    getApartmentByID = (query: string) => this._request(query, {
        method: "GET",
        headers: {
            ...this.generate_header(),
        },
    });

    getDashboardBaner = (): Promise<CustomAxiosResponse> => this._request("banners", {
        headers: {
            ...this.generate_header(),
        },
        method: 'GET'
    });
    getDashboardPartners = (): Promise<CustomAxiosResponse> => this._request("partners", {
        headers: {
            ...this.generate_header(),
        },
        method: 'GET'
    });

    getMapModels = () => this._request(`maps`, {
        method: "GET",
    })

    changeModelAttributes = (data: any, id: number) => this._request(`maps/${id}`, {
        headers: {
            ...this.generate_header(),
        },
        method: "PUT",
        data
    })


    removeModel = (id: number) => this._request(`maps/${id}`, {
        headers: {
            ...this.generate_header(),
        },
        method: "DELETE",
    })

    addModels = (data: any) => this._request('maps', {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })

    getCountries = (params?: queryType): Promise<CustomAxiosResponse> => this._request('countries', {
        method: 'GET',
        params
    })

    getBrands = (type: string, isGaming: boolean) => this._request(`brands?type=${type}&isGaming=${isGaming}`, {
        headers: {
            ...this.generate_header(),
        },
        method: "GET",
    })

    uploadVideo = (data: FormData, roomId: string | undefined) => this._request(`rooms/${roomId}/models`, {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })

    uploadImage = (data: FormData, roomId: string | undefined) => this._request(`rooms/${roomId}/models`, {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })
    uploadPdf = (data: FormData, roomId: string | undefined) => this._request(`rooms/${roomId}/models`, {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })

    addModel = (data: FormData, roomId: string | undefined) => this._request(`rooms/${roomId}/models`, {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })

    generateAgoraToken = (data: any) => this._request(`generate-agora-token`, {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })

    blockUser = (data: any, roomId: string | undefined) => this._request(`rooms/${roomId}/users/block`, {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })

    unBlockUser = (roomId: string | undefined, userId: string | undefined) => this._request(`rooms/${roomId}/users/${userId}/unblock`, {
        headers: {
            ...this.generate_header(),
        },
        method: "DELETE",
    })

    kickOffUser = (data: any, roomId: string | undefined) => this._request(`rooms/${roomId}/users/kick`, {
        headers: {
            ...this.generate_header(),
        },
        method: "POST",
        data
    })

    // unkickUser = (roomId: string | undefined, userId: any) => this._request(`rooms/${roomId}/users/${userId}/unkick`, {
    //     headers: {
    //         ...this.generate_header(),
    //     },
    //     method: "DELETE",
    // })

    handleLikeRoom = (roomId: number) => this._request(`users/like-dislike/${roomId}`, {
        method: "POST",
        headers: {
            ...this.generate_header(),
        },
    });

    setRoomStatus = (id: number, status: number): Promise<CustomAxiosResponse> => this._request(`rooms/${id}/status`, {
        method: "PUT",
        headers: {
            ...this.generate_header(),
        },
        data: { status },
    });

    deleteRoom = (id: number): Promise<CustomAxiosResponse> => this._request(`rooms/${id}`, {
        method: "DELETE",
        headers: {
            ...this.generate_header(),
        },
    });
    setRoomName = (id: number, value: string): Promise<CustomAxiosResponse> => this._request(`rooms/${id}/rename`, {
        method: "POST",
        data: {
            name: value
        },
        headers: {
            ...this.generate_header(),
        },
    });

    clearContent = (id: string): Promise<CustomAxiosResponse> => (
        this._request(`rooms/${id}/reset`, {
            method: "POST",
            headers: {
                ...this.generate_header(),
            },
        })
    );

    saveTemplate = (id: any) =>
        this._request(`rooms/${id}/templates`, {
            method: "POST",
            headers: {
                ...this.generate_header(),
            }
        });

    getPayments = () =>
        this._request(`payment-methods`, {
            method: "POST",
            headers: {
                ...this.generate_header(),
            },
        });
    submitDeposit = (data: any) =>
        this._request(`deposit`, {
            method: "POST",
            headers: {
                ...this.generate_header(),
            },
            data,
        });

    setUserAvatar = (data: Partial<{ 'glb': string }>) => this._request("dashboard/ready-player-avatar", {
        method: "POST",
        headers: {
            ...this.generate_header(),
        },
        data,
    });

    getAvaturnSession = () => this._request("dashboard/avaturn-session", {
        method: "POST",
        headers: {
            ...this.generate_header()
        },
    });

    endAvaturnSession = (data: Partial<{
        'avatar_url': string,
        "user_id": string | number,
        "avatar_id": string | number
    }>) => this._request("dashboard/avaturn-end-session", {
        method: "POST",
        headers: {
            ...this.generate_header()
        },
        data
    });


    getLocations = () =>
        this._request(`locations`, {
            method: "GET",
        });

    getEvents = (query?: any) =>
        this._request(`events`, {
            method: "GET",
            params: query
        });

    getEventsCount = () =>
        this._request(`event-count`, {
            method: "GET",
        });

    getDashboardVideos = () =>
        this._request(`partners`, {
            method: "GET",
        });

    getAllTables = () =>
        this._request(`rooms/${unityProviderStore.roomData.id}/chairs`, {
            method: "GET",
            headers: {
                ...this.generate_header()
            },
        });

    reserveTable = (data: {
        chairs: number[],
        peopleCount: number,
        reservedDate: string
    }) =>
        this._request(`rooms/${unityProviderStore.roomData.id}/chairs-reserve`, {
            method: "POST",
            headers: {
                ...this.generate_header()
            },
            data
        });

    unreserveTable = (data: {
        chairs: number[]
    }) =>
        this._request(`rooms/${unityProviderStore.roomData.id}/chairs-unreserve`, {
            method: "PUT",
            headers: {
                ...this.generate_header()
            },
            data
        });

    getCategories = () =>
        this._request(`categories`, {
            method: "GET",
            headers: {
                ...this.generate_header()
            },
        });

    setInviteUser = (emails: any[]) =>
        this._request(`users/send-invitation/${unityProviderStore.roomData.id}`, {
            method: "POST",
            headers: {
                ...this.generate_header(),
            },
            data: {
                emails,
            },
        });

    changeRoomHooryToken = (data: {}) => this._request(`rooms/${unityProviderStore.roomData.id}/set-hoory-token`, {
        headers: {
            ...this.generate_header(),
        },
        method: 'PUT',
        data,
    });

    createPortal = (data: any) => this._request(`rooms/${unityProviderStore.roomData.id}/portals`, {
        method: "POST",
        headers: {
            ...this.generate_header(),
        },
        data,
    });

    generateNonce = (data: any) => this._request("generate-none", {
        method: "POST",
        data,
    });

    getWalletToken = (data: any) => this._request("verify-wallet", {
        method: "POST",
        data,
    });

    makeUserAdmin = (data: { userIds: number[] }) => this._request(
        `rooms/${unityProviderStore.roomData.id}/admins`,
        {
            method: "POST",
            data,
            headers: {
                ...this.generate_header(),
            },
        }
    );

    removeAdminRole = (id: number | string) => this._request(
        `rooms/${unityProviderStore.roomData.id}/admins/${id}`,
        {
            method: "DELETE",
            headers: {
                ...this.generate_header(),
            },
        }
    );

    freezeUser = (data: {
        userIds: number[]
    }) => this._request(
        `rooms/${unityProviderStore.roomData.id}/users/freeze`,
        {
            method: "POST",
            headers: {
                ...this.generate_header(),
            },
            data
        }
    );

    unfreezeUser = (id: number) => this._request(
        `rooms/${unityProviderStore.roomData.id}/users/${id}/unfreeze`,
        {
            method: "DELETE",
            headers: {
                ...this.generate_header(),
            },
        }
    );

    setUploadTicket = (data: FormData, id: number) => this._request(`rooms/${id}/tickets`, {
        method: "POST",
        headers: {
            ...this.generate_header()
        },
        data
    });

    setSocialLoginToken = (data: { jwt: string, provider: string }) => this._request("google-apple-auth", {
        method: "POST",
        data,
    });

    getBuildUrls = (version:string) => this._request("unity-files", {
        method: "POST",
        data:{
            version
        }
    });
}

class ApiServiceDepartments extends ApiService {
    constructor(public axios: AxiosInstance) {
        super(axios, cacheService)
    }

    getHotels = (query?: any) => this._request("hotels", {
        method: "GET",
        params: query
    });

    getHotelRooms = (id: string) => this._request(`hotels/${id}/rooms`, {
        method: "GET",
    });

    getHotelRoom = (hotelId: string, roomId: string) => this._request(`hotels/${hotelId}/rooms/${roomId}`, {
        method: "GET",
    });

    getHotelsTypes = () => this._request("hotels/types", {
        method: "GET",
    });

    getHotelsAmenities = () => this._request("hotels/amenities", {
        method: "GET",
    });

    getHotelsMeals = () => this._request("hotels/meals", {
        method: "GET",
    });

    getRestaurants = (query?: any) => this._request("restaurants", {
        method: "GET",
        params: query
    });

    getRestaurantTypes = () => this._request("restaurants/types", {
        method: "GET",
    });

    getRestaurantCuisines = () => this._request("restaurants/cuisines", {
        method: "GET",
    });

    getRestaurantAmenities = () => this._request("restaurants/amenities", {
        method: "GET",
    });

    getApartments = (query?: any) => this._request(`apartments`, {
        method: "GET",
        params: query
    });

    getApartmentTypes = () => this._request("apartments/types", {
        method: "GET",
    });

    getApartmentStaticFilters = () => this._request("apartments/filters", {
        method: "GET",
    });

    getApartmentAmenities = () => this._request("apartments/amenities", {
        method: "GET",
    });

    getBanks = () => this._request("banks", {
        method: "GET",
    });

    getAutocompleteSearchData = (query: any) => this._request("copia/autocomplete-search", {
        method: "GET",
        params: query
    });
}

export const apiServiceBase: ApiServiceBase = new ApiServiceBase(axios.create({
    baseURL: process.env.REACT_APP_API_URL,
}))

export const apiServiceUser: ApiServiceUsers = new ApiServiceUsers(axios.create({
    baseURL: process.env.REACT_APP_API_URL + 'users/'
}))

export const apiServiceRoom: ApiServiceRoom = new ApiServiceRoom(axios.create({
    baseURL: process.env.REACT_APP_API_URL + 'room/'
}))

export const apiServiceDepartments: ApiServiceDepartments = new ApiServiceDepartments(axios.create({
    baseURL: 'https://api.yocerebrum.com/api/v1/'
}))

export const apiServiceEcommerce: ApiServiceEcommerce = new ApiServiceEcommerce(axios.create({
    baseURL: process.env.REACT_APP_ECOMMERCE || "https://space-api.pandamr.io/ecommerce/",
}))

export const apiServiceDefault: ApiServiceDefault = new ApiServiceDefault(axios.create())

export const apiServiceV2: ApiServiceV2 = new ApiServiceV2(axios.create({
    baseURL: 'https://api.yocerebrum.com/api/v1/',
}))