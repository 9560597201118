import {makeAutoObservable} from 'mobx';
import {roomModelControllerStore} from '../roomModelController/roomModelController.store';
import {unityfooterStore} from '../unityfooter/unityfooter.store';
import {sendMessageStore} from '../../modules/unityLayout/sendMessage.store';
import {
    NftDataType,
    TCallData,
    TPlayerUserData,
    TPortalData,
    UnityLayout
} from '../../modules/unityLayout/unityLayout.type';
import {participantsStore} from '../participents/participants.store';
import {globalStore} from '../../global.store';
import {agoraStore} from '../../modules/scenetemplate/stores/agora.store';
import {scenetemplateStore} from '../../modules/scenetemplate/stores/scenetemplate.store';
import {cacheService} from '../../services/cacheService';
import {shopEcommerceStore, TShopItem} from '../ShopEcommerce/shopEcommerce.store';
import {popokGameStore} from '../PopokGame/community.store';
import {pdfStore} from '../pdfIframe/pdf.store';
import {apiServiceDepartments, apiServiceV2} from '../../services/apiService';
import {builds, CallStatus, MICROPHONE_STATUS} from '../../modules/scenetemplate/hooks/constants';
import {ucraftStore} from '../../ucraft.store';
import {customSort, getKickUserTime} from '../../helpers/utils';
import {checkEventsDates, isVisitor} from '../../helpers/isVisitor';
import {isAdmin, isCreator} from '../../helpers/isAdminOrCreator';
import {ticketupload} from '../TicketUpload/ticketupload.store';
import {privateTalkStore} from '../../modules/scenetemplate/stores/privateTalk.store';
import {logoutPopupStore} from '../logoutPopup/logoutPopup.store';
import {reserveTablePopupsStore} from '../reserveTablePopups/reserveTablePopups.store';
import {transformUserData} from './helpers/transformData';
import {isMobile} from "react-device-detect";
import {cardsblockStore} from "../cardsblock/cardsblock.store";

export type keyFunction = keyof typeof UnityProviderStore;

interface UnityInstance {
    SendMessage: (gameObjectName: string, methodName: string, value?: string) => void;
}


const {
    privateCallHandler,
    inviteHandler,
    responseCallUser,
    leavePrivateCall,
} = privateTalkStore;

class UnityProviderStore {
    instance: any = null;
    isLoaded: boolean = false;
    controllersProps: any;
    locked: boolean = false;
    isActiveControllers: boolean = false;
    portalData: TPortalData | null;
    roomData: any = {};
    micPermission: boolean = false;
    showFaq: boolean = false;
    blockedUsers: any = [];
    roomDataStatus: boolean = false;
    hooryToken: string = "";
    roomId: string;
    searchValue: string = "";
    avatarPosition: any;
    roomUsersState: UnityLayout.RoomUsersState[] = [];
    filteredUsers: UnityLayout.RoomUsersState[] = [];
    unityLoaded: boolean = false;
    hideView: boolean = false;
    loadingProgression: number = 0;
    updateTexture: Function;
    openUrl: string = "";
    nftData: NftDataType;
    build: any = {};
    loader: string = '';
    copiaData: any = null;
    hotelRooms: any = null;
    bannerUrl: any = null;
    bannerData: any = {};

    constructor() {
        makeAutoObservable(this);
    }

    get getMyOptions() {
        const res = this.roomUsersState.filter(
            (user) => user.publicId == cacheService.getMyId
        )[0];
        return {...res, isCreator: this.isMyRoom};
    }

    get renderRoomUsers() {
        if (this.searchValue) {
            return this.filteredUsers;
        } else {
            return this.roomUsersState;
        }
    }

    get isMyRoom() {
        const res = this.roomData?.creator?.id == cacheService.getMyId;
        return res;
    }

    callHandler = (eventName: keyFunction, ...args: any[]) => {
        console.log(eventName, "eventNameeventNameeventName");
        
        const handler = this[eventName as keyof UnityProviderStore];
        if (typeof handler === 'function') {
            handler.call(this, ...args);
        } else {
            console.warn(`Handler ${eventName} does not exist on UnityProviderStore`);
        }
    };

    dispatchReactUnityEvent = this.callHandler

    setLoadingProgression = (num: number) => {
        this.loadingProgression = Math.floor(num * 100);
    }

    setRooomData = (name: string) => {
        this.roomData.name = name;
    };

    onLikeRoom = async (roomId: string | undefined) => {
        const res = await apiServiceV2.handleLikeRoom(Number(roomId));
        if (!res.error) {
            this.roomData = {
                ...this.roomData,
                isLiked: res.data.isLiked === 1,
                likesTotalCount: this.roomData.likesTotalCount + (res.data.isLiked === 1 ? 1 : -1),
            };
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
    };

    setBlockHooryClicks(bool: boolean) {
        // this.blockHooryClicks = bool
    }


    onLoaderUnitLoaded = (e: any) => {
        this.setLoadingProgression(e)
    };

    onKickOffUser = (userId: number, roomId: number, message: string) => {
        if (userId === Number(cacheService.get("Id"))) {
            const time = message.split("kicked for ")[1];
            const hours = time.endsWith("hours") ? time.split(" hours")[0] : 0;
            const minutes = time.endsWith("minutes") ? time.split(" minutes")[0] : 0;
            const data = {
                hours: hours,
                minutes: minutes
            }
            globalStore.setNotifications(
                true,
                "start_kicked",
                `You have been kicked for`,
                data
            );
            this.isLoaded = false;
            this.roomData = {};
            sendMessageStore.sendMessage("ReactManager", "UnloadSpace", `${roomId}`);
            globalStore.navigation("/dashboard/cerebrum");
        }
    };

    setInstance = (instance: any) => {
        this.instance = instance
    }

    setIsLoaded = (bool: boolean) => {
        this.isLoaded = bool
    }

    setBannerUrl = (data: any) => {
        this.bannerUrl = data
    }

    setOpenUrl = (str: any) => {
        this.openUrl = str
    }

    onOpenRaffle = (...data: any) => {
        console.log(data);
    };

    onGuestOpenRaffle = (...data: any) => {
        console.log(data);
    };

    onOpenBannerSaleNft = (...data: any) => {
        console.log(data);
    };

    onOpenCerebrumNft = (...data: any) => {
        console.log(data);
    };

    onUserJoined = (username: string) => {
        if (username === globalStore.userData.username) {
            sendMessageStore.sendMessage("ReactManager", "UnloadSpace", `${cacheService.getRoomId}`);
            // globalStore.logout()
            cacheService.clear();
            logoutPopupStore.setShowLogoutPopup(false)
            globalStore.userData = null;
            globalStore.isLogin = false
            globalStore.navigation("/dashboard/cerebrum")
            globalStore.setNotifications(true, 'duble_login');
        }
    };

    onMicrophoneUseAbilityChanged = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === id) {
                participantsStore.user.micPermissions = state;
                user.micPermissions = state;
            }
            return user;
        })
    };


    onVoucherCopied = (...data: any) => {
        console.log(data);
    };

    onVigogoExploreExpired = (...data: any) => {
        console.log(data);
    };

    onMicrophonePermissionChanged = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === id) {
                participantsStore.user.micPermissionGranted = state;
                user.micPermissionGranted = state;
            }
            return user;
        })
    };

    onLeaveFromGame = (...data: any) => {
        console.log(data);
    };

    onHideView = (...data: any) => {
        this.isLoaded = true;
        this.hideView = true;
        console.log(data);
    };

    onUserAdminRollChanged = (publicId: number) => {
        const condidate = participantsStore.adminUsers.filter((user: any) => user.id == publicId)[0];
        if (!condidate) {
            const curUser = this.roomUsersState.filter(
                (user: any) => user.publicId == publicId
            )[0];
            participantsStore.setAdminUsers([...participantsStore.adminUsers, {
                username: curUser.username,
                id: curUser.publicId
            }])
            if (cacheService.getMyId === publicId) globalStore.setNotifications(true, 'admin')
        } else {
            participantsStore.adminUsers = participantsStore.adminUsers.filter(
                (user: any) => user.id != publicId
            );
            if (cacheService.getMyId === publicId) globalStore.setNotifications(true, 'remove_admin')
        }
        unityProviderStore.roomUsersState.map((user: any) => {
            return {
                ...user,
                isAdmin: !!condidate,
            }
        })
    }

    searchHandler = (val: string) => {
        this.searchValue = val;
        if (!!val.length) {
            const filteredUsers = [...customSort(this.roomUsersState, val)];
            this.filteredUsers = filteredUsers;
        } else {
            this.filteredUsers = [];
        }
    };

    onSceneUnLoaded = (...data: any) => {
        console.log(data);
    };

    setLocked = (bool: boolean) => {
        this.locked = bool;
    };

    onReturnCurrentUsersIds = (...data: any) => {
        console.log(data);
    };

    onAgoraIndividualInviteAnswer = (...data: any) => {
    };

    onFullScreenShare = (...data: any) => {
        console.log(data);
    };

    onUserLeaved = (...data: any) => {
        console.log(data, "Leaved");
    };

    onSelectWheel = (...data: any) => {
        console.log(data);
    };

    // onOpenBanner = (url: any) => {
    //     const data = JSON.parse(url);
    //     if (!data.banner_url) {
    //         popokGameStore.setGameUrl(data.url);
    //     } else {
    //         this.setBannerUrl({
    //             url: data.url, imgUrl: data.banner_url,
    //         });
    //     }
    // };

    onOpenBanner = (data: any) => {
        // const bannerData = JSON.parse(data)
        this.setBannerData({
            url: "https://www.fastshift.am/en/",
            imgUrl: "https://fv-media.storage.googleapis.com/environment_bundle/T-shirt.glb"
        });
    };

    setBannerData = (data: any) => {
        this.bannerData = data;
    }

    onSetAsEnvironment = (...data: any) => {
        console.log(data);
    };

    onUploadNft = (...data: any) => {
        console.log(data);
    };

    onZoomNft = (...data: any) => {
        console.log(data);
        this.nftData = JSON.parse(data[0])
    };

    onDeleteNft = (...data: any) => {
        console.log(data);
    };

    onOpenBook = (...data: any) => {
        pdfStore.setPdfUrl(data[0]);
    };

    onOpenSlotGame = (url: string) => {
        const token = globalStore.userData.externalToken;
        const gameUrl = url + `&externalToken=${token}`
        popokGameStore.setGameUrl(gameUrl)
    };

    onOpenGamblingTable = (data: any) => {
        // console.log(JSON.parse(data));
    };

    onOpenEcommerceProduct = (data: any) => {
        const res: TShopItem = JSON.parse(data)
        shopEcommerceStore.setShopData(res)
        shopEcommerceStore.setShopPopup(true)

    };

    OnOpenHooryForUser = (...data: any) => {
        console.log(data);
    };

    onOpenHooryWidget = (...data: any) => {
        console.log(data);
    };

    OnSelectionTransformChange = (...data: any) => {
        console.log(data);
    };


    onCurrentTransformCommandIndexChanged = (step: any) => {
        roomModelControllerStore.setCurrentStep(step)
    };

    onTransformCommandsCountChanged = (step: any) => {
        roomModelControllerStore.setEndStep(step)
    };

    onOpenPortalToSpace = (roomId: number, name: string, image: string) => {
        sendMessageStore.lockKeyboardInput();
        this.portalData = {roomId, name, image};
    };

    onOpenPetanqueEventPage = () => {
        this.onOpenPortalToSpace(829694783, "Tamada's Virtual Concert at Petanque Digital Club", "https://fv-media.storage.googleapis.com/room_preview_image/P03.png")
    };

    onObjectDeselected = () => {
        this.setIsActiveControllers(false);
        if (unityfooterStore.showModelController) {
            unityfooterStore.setShowModelController(false);
        }
    };

    onObjectSelected = (publicId: number, type: number, coordinate: string, url: string, isSelected: number) => {
        this.setControllersProps({
            publicId,
            type,
            coordinate,
            url,
            isSelected,
        });
        roomModelControllerStore.setInitControllers({
            publicId,
            type,
            coordinate: JSON.parse(coordinate),
            url
        })
        this.setLocked(!!isSelected);
        this.setIsActiveControllers(true);
    };

    onFreezeStateForUserChanged = (id: any, state: any) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === Number(id)) {
                participantsStore.user.freezedValue = Boolean(state);
                user.freezedValue = Boolean(state);
            }
            return user;
        });
        if (id === cacheService.getMyId) {
            if (Boolean(state)) {
                globalStore.setNotifications(true, "frozen");
                agoraStore.videoEnabled && scenetemplateStore.videoToggle();
                agoraStore.micEnabled && scenetemplateStore.audioToggle();
            } else {
                globalStore.setNotifications(true, "unfrozen");
            }
        }
    };

    onMicStateForUserChanged = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map(user => {
            if (user.publicId === id) {
                participantsStore.user.talkingValue = state;
                user.talkingValue = state;
            }
            return user;
        })

        if (id === cacheService.getMyId) {
            return scenetemplateStore.audioToggle(state)
        }
    };

    onRemoveUserAudioState = (id: number) => {
        this.roomUsersState = this.roomUsersState.filter(
            (user) => user.publicId !== id
        );
    };

    onSpeakingStateForUserChanged = (...data: any) => {
        console.log(data);
    };

    setIsActiveControllers = (bool: boolean) => {
        this.isActiveControllers = bool;
    }

    setControllersProps = (data: any) => {
        this.controllersProps = data;
    }


    onPhotonConnectionIsReady = () => {
        this.instance.SendMessage("ReactManager", "Login", transformUserData(globalStore.userData));
        this.instance.SendMessage("ReactManager", "OpenRoom", this.roomId);
    }

    onAddUserAudioState = (
        username: string,
        publicId: number,
        talkingValue: number,
        micValue: number,
        freezedValue: boolean,
        haveMic: number,
        isGuest: number,
        privateCallState: number,
        isFastBankUser: number,
        micPermissions: number,
        micPermissionGranted: number,
        email: string,
    ) => {
        const data: UnityLayout.RoomUsersState = {
            username,
            publicId,
            email,
            talkingValue,
            micValue,
            freezedValue: unityProviderStore.roomData.freezeUsers.some((freezeUser: any) => freezeUser.id == publicId),
            haveMic,
            isGuest: Boolean(isGuest),
            privateCallState,
            isFastBankUser,
            micPermissions,
            micPermissionGranted,
            isCreator: this.userIsCreator(publicId),
            isAdmin: participantsStore.userIsAdmin(publicId),
        };
        this.roomUsersState = [...this.roomUsersState, data];
    };

    userIsCreator = (id: number) => {
        const res = this.roomData.creator.id == id;
        return res;
    };

    onSceneFullyLoaded = (...data: any) => {
        this.setLoadingProgression(1)
    }

    onReserveTable = reserveTablePopupsStore.onReserve

    hasUserOnFreeze = (id = cacheService.getMyId) => {
        return this.roomUsersState.filter((user) => user.publicId === id)[0]
            ?.freezedValue;
    };

    muteAllUsers = () => {
        sendMessageStore.sendMessage("ReactManager", "SetOtherUserMicrophoneState", JSON.stringify({
            id: 0,
            mic: false
        }));
    }

    onRoomMicrophoneUseAbilityChanged = () => {
        this.micPermission = !this.micPermission;
    };

    micActiveHandler = () => {
        sendMessageStore.sendMessage("ReactManager", "GrantMicUseAbilityToOtherUsers", JSON.stringify({
            micPermissionGranted: !this.micPermission,
            userIds: null
        }));
        this.onRoomMicrophoneUseAbilityChanged()
    }

    handleFreeze = async (user: UnityLayout.RoomUsersState, roomId: number) => {
        const event = user.freezedValue
            ? MICROPHONE_STATUS[0]
            : MICROPHONE_STATUS[2];
        try {
            if (event === "FREEZE") {
                await apiServiceV2.freezeUser(
                    {
                        userIds: [user.publicId]
                    }
                );
                sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                    command: "11",
                    data: {
                        "userId": user.publicId,
                        "state": true,
                    }
                }))
            } else {
                await apiServiceV2.unfreezeUser(user.publicId);
                sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                    command: "11",
                    data: {
                        "userId": user.publicId,
                        "state": false,
                    }
                }))
            }
            this.roomUsersState = this.roomUsersState.map((item) => {
                if (item.publicId === user.publicId) {
                    participantsStore.user.freezedValue = !user.freezedValue;
                    item.freezedValue = !user.freezedValue;
                }
                return item;
            });
        } catch (e: any) {
            console.warn("ERROR: ", e.message);
        }
    };

    makeAdminHandler = async (
        user: UnityLayout.RoomUsersState,
    ) => {
        try {
            await apiServiceV2.makeUserAdmin({
                userIds: [user.publicId],
            });
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "10",
                data: {
                    "admins": [
                        {
                            "public_id": Number(user.publicId),
                            "username": user.username
                        }
                    ],
                    "room_public_id": Number(unityProviderStore.roomData.id),
                }
            }))
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "10",
                data: {
                    "admins": [
                        {
                            "public_id": Number(user.publicId),
                            "username": user.username
                        }
                    ],
                    "userId": Number(user.publicId),
                    "email": user?.email,
                    "username": user.username,
                    "roomId": Number(unityProviderStore.roomData.id),
                    "IsAdmin": true,
                }
            }))
        } catch (e: any) {
            console.warn("ERROR: ", e.message);
        }
    };

    handleBlockUser = async (
        user: UnityLayout.RoomUsersState | undefined,
        roomId: string | undefined
    ) => {
        try {
            await apiServiceV2.blockUser({
                userIds: [user?.publicId as number],
            }, roomId);
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "9",
                data: {
                    "room_public_id": unityProviderStore.roomData.id,
                    "user_public_id": user?.publicId,
                }
            }))
            this.blockedUsers = [
                ...this.blockedUsers,
                {id: user?.publicId, username: user?.username},
            ];
        } catch (e: any) {
            console.warn("ERROR: ", e.message);
        }
    };
    onBlockUser = (userId: number, roomId: number) => {
        if (userId === Number(cacheService.get("Id"))) {
            globalStore.setNotifications(true, "block");
            this.isLoaded = false;
            this.roomData = {};
            sendMessageStore.sendMessage("ReactManager", "UnloadSpace", `${roomId}`);
            globalStore.navigation("/dashboard/cerebrum");
        }
    };

    onUserMicAvailable = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === id) {
                participantsStore.user.micIsExist = Boolean(state);
                user.micIsExist = Boolean(state);
            }
            return user;
        });
    };

    clearRoomUsersState = () => {
        this.roomUsersState = []
    }

    removePortalData = () => {
        this.portalData = null;
        sendMessageStore.unLockKeyboardInput();
    }

    onPortalTo = async (leave: any) => {
        await leave()
        this.roomData.previewImage = this.portalData?.image;
        this.roomData.name = this.portalData?.name;
        this.isLoaded = false;
        this.setLoadingProgression(0);
        sendMessageStore.sendMessage(
            "ReactManager",
            "UnloadSpace",
            `${this.roomId}`
        );
        this.setRoomDataStatus(false);
        this.clearRoomUsersState();
        globalStore.navigation(`/room/${this.portalData?.roomId}`);
        this.removePortalData();
    }

    onReturnNearestTransform = (data: any) => {
        let position = JSON.parse(data);
        this.avatarPosition = JSON.stringify(position);
    };

    OnPhotonConnectionError = (...data: any) => {
        console.log(data);
    };

    userInCall = (id: number) => {
        const inCall =
            this.roomUsersState.filter(
                (user) => user.publicId === id
            )[0].privateCallState !== 0;
        if (inCall) {
            globalStore.setToasts(true, "inprivatcall");
        }
        return inCall
    }

    setHooryToken = (token: string) => {
        this.hooryToken = token
    }

    setCopiaData = (copiaData: any) => {
        this.copiaData = copiaData;
    }

    setHotelRooms = (hotelRooms: any) => {
        this.hotelRooms = hotelRooms;
    }

    setRoomData = (roomData: any) => {
        participantsStore.setAdminUsers(roomData.admins);
        this.blockedUsers = roomData.blockedUsers;
        this.setHooryToken(roomData.hooryWebsiteToken)
        return (this.roomData = {
            ...this.roomData,
            ...roomData
        });
    };

    hasEventRoom = (room: any): boolean => {
        return !!room?.eventData.length;
    };

    onOpenUserMenu = (...data: any) => {
        const playerData: TPlayerUserData = JSON.parse(data);
        const inCall = this.userInCall(playerData.public_id)

        if (inCall) return null
        privateCallHandler({
            public_id: playerData.public_id,
            username: playerData.username,
        });
    };

    handleDownload = () => {
        fetch(this.controllersProps.url, {
            method: "GET",
            headers: {
                "Content-Type": "application/*",
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `file.${this.controllersProps.url.split(".").pop()}`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            });
    };

    onInviteToAgoraChanel = (data: string) => {
        const callData: TCallData = JSON.parse(data);
        if (cacheService.getMyId === callData.target_user) {
            const sender = this.roomUsersState.filter(
                (user) => user.publicId === callData.sender_user
            )[0];
            inviteHandler(callData, sender);
        }
    };

    onPrivateCallStateChanged = (id: number, state: CallStatus) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === id) {
                participantsStore.user.privateCallState = state;
                user.privateCallState = state;
            }
            return user;
        });
        responseCallUser(id, state);
    };

    onRemovedFromCall = () => {
        leavePrivateCall();
    };

    handleLock = () => {
        sendMessageStore.sendMessage("ReactManager", "ToggleLockObject");
        this.setControllersProps({
            ...this.controllersProps,
            isSelected: !this.locked
        });
        this.setLocked(!this.locked);
    };

    handleUnBlockUser = async (uid: any, roomId: string | undefined) => {
        try {
            await apiServiceV2.unBlockUser(roomId, uid);
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "9",
                data: {
                    "room_public_id": roomId,
                    "user_public_id": uid,
                }
            }))
            this.blockedUsers = this.blockedUsers.filter(
                (user: any) => user.id !== uid
            );
        } catch (error) {
            console.log(error);
        }
    };

    getBuildData = async (version: string) => {
        const {data}: any = await apiServiceV2.getBuildUrls(version);
        this.loader = data.loader;
        this.build = {
            dataUrl: data.data,
            frameworkUrl: data.framework,
            codeUrl: data.wasm,
        };
    }

    clearBuilds = () => {
        this.loader = "";
        this.build = {};
    }

    getApartmentData = async (id: string | undefined, type: string | undefined, hotelRoomId?: string | undefined) => {
        await this.getBuildData(builds.copia)
        if (hotelRoomId !== undefined) {
            const res: any = await apiServiceDepartments.getHotelRoom(`${id}`, hotelRoomId);
            if(res.error) {
                globalStore.setNotifications(true, "room_error");
            } else {
                const result = res.data?.result;
                if (result) {
                    this.setCopiaData(result);
                    cacheService.set("roomId", id);
                    this.setRoomData(result);
                    this.setRoomDataStatus(true);
                }
            }
        } else {
            const res: any = await apiServiceV2.getApartmentByID(`${type}/${id}`);
            if(res.error) {
                globalStore.setNotifications(true, "room_error");
            } else {
                const result = res.data?.result;
                if(result) {
                    this.setCopiaData(result);
                    cacheService.set("roomId", id);
                    this.setRoomData(result);
                    this.setRoomDataStatus(true);
                    if (type === "hotels") {
                        const hotelRooms: any = await apiServiceDepartments.getHotelRooms(`${id}`);
                        if(!res.error) {
                            this.setHotelRooms(hotelRooms.data?.result);
                        }
                    }
                }
            }
        }
    }

    getRoomData = async (id: string = "") => {
        const pathname = window.location.pathname;
        try {
            const myId = cacheService.getMyId
            this.roomId = id;
            const res: any = await apiServiceV2.getRoomByID(id);
            console.log("////", isMobile, globalStore.isLogin)
            if(isMobile) {
                if(globalStore.isLogin) return cardsblockStore.setOpenInAppData(res.data);
            }
            await this.getBuildData(isMobile ? builds.mobile : builds.spaces);

            if (!res.error) {
                const blockedUser = res.data.blockedUsers.filter((user: any) => user.id == myId)
                const kickedUser = res.data.kickedUsers.filter((user: any) => user.id == myId)
                if (blockedUser.length) {
                    this.setRoomDataStatus(false);
                    return globalStore.setNotifications(true, "start_block");
                }
                if (kickedUser.length) {
                    const dateString = getKickUserTime(kickedUser[0].remove_kick_date);
                    let time: string = "";
                    if (dateString.hours > 0) time += `${dateString.hours} hours`;
                    if (dateString.minutes > 0)
                        time += ` ${dateString.minutes} minutes`;
                    if (dateString.seconds > 0)
                        time += ` ${dateString.seconds} seconds`;
                    if (time.length) return globalStore.setNotifications(
                        true,
                        "start_kicked",
                        `You have been kicked for`,
                        dateString
                    );
                }

                cacheService.set("roomId", id);
                const {eventData, creator, admins, roomType, visitors} = res.data;
                if (!globalStore.isLogin && (roomType === 18 || roomType === 17 || roomType === 16)) {
                    cacheService.clear();
                    globalStore.navigation(
                        "/dashboard/cerebrum"
                    );
                    return globalStore.setGuestPopupState(true, pathname);
                }
                if (
                    this.hasEventRoom(res.data) && res.data.eventData[0].eventType === 2 && (!isVisitor(visitors, globalStore.userData.id) ||
                        (isVisitor(visitors, globalStore.userData.id) && checkEventsDates(eventData[0]?.eventStartDate, eventData[0]?.eventEndDate) && !isCreator(creator.id) && !isAdmin(admins)))) {
                    if (!globalStore.isLogin) {
                        cacheService.clear();
                        globalStore.navigation(
                            "/dashboard/cerebrum"
                        );
                        return globalStore.setGuestPopupState(true, pathname);
                    }
                    // globalStore.navigation("/");
                    ticketupload.uploadTicketPopup(eventData[0], +id, visitors);
                } else {
                    this.setRoomData(res.data);
                    this.setRoomDataStatus(true);
                }
                if (res.data.ecommerce_domain && res.data.ecommerce_id) {
                    ucraftStore.setEcommerceData(
                        res.data.ecommerce_domain,
                        res.data.ecommerce_id
                    );
                    ucraftStore.setEcommerceConnected(true);
                }
            } else {
                if (res.message === 'Authentication failed.' || res.message === "Token is not found or expired.") {
                    cacheService.clear();
                    globalStore.setIsLogin(false);
                    globalStore.navigation(
                        "/dashboard/cerebrum"
                    );
                    return globalStore.setGuestPopupState(true, pathname);

                }
                this.setRoomDataStatus(false);
                if (res?.errors?.code === 69) {
                    let time: string = "";
                    if (res.errors.hours) time += `${res.errors.hours} hours`;
                    if (res.errors.minutes)
                        time += ` ${res.errors.minutes} minutes`;
                    if (res.errors.seconds)
                        time += ` ${res.errors.seconds} seconds`;
                    // globalStore.setNotifications(
                    //     true,
                    //     "start_kicked",
                    //     `You have been kicked for`,
                    //     dateString
                    // );
                } else {
                    globalStore.setNotifications(true, "room_error");
                }
            }
        } catch(e: any) {
            console.log("ERROR: ", e.message);
            return globalStore.setGuestPopupState(true, pathname);
        }
    };


    onGetBundle = () => {
        sendMessageStore.sendMessage("ReactManager", "LoadSceneBundle", this.roomData.bundle[0].model);
    }

    onGetPlatform = () => {
        sendMessageStore.sendMessage("ReactManager", "SetPlatform", isMobile ? "Mobile" : "Desktop");
    }

    onGetEnviro = () => {
        sendMessageStore.sendMessage("ReactManager", "SetIsEnviro", "false");
    }

    setUpdateTexture = (func: Function) => {
        this.updateTexture = func
    }

    onOpenUrl = (url: string, isIframe: number = 1) => {
        if (isIframe === 1) this.openUrl = url;
        else if (url !== "") window.open(url);
    }

    onOpenTerminal = () => {
        this.setShowFaq(true);
    }

    setShowFaq = (state: boolean) => {
        this.showFaq = state;
    }


    setNftData = (data: NftDataType) => {
        this.nftData = data;
    }

    setRoomDataStatus = (state: boolean) => {
        this.roomDataStatus = state;
    }

    removeState = () => {
    };
}

export const unityProviderStore = new UnityProviderStore();

