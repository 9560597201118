import styles from "./ticketNumberSection.module.scss";
import Button from "../../../../ui/button";
import ticketNumberImg from "../../../../assets/images/ortak/ticket_number_and_prize.png";
import {useNavigate} from "react-router-dom";

const TicketNumberSection = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.popokPropertySection}>
            <div className={styles.popokPropertyInfo}>
                <h2 className={styles.popokPropertyTitle}>Ticket Numbers and Prize Tiers</h2>
                <div className={styles.popokPropertyDescriptionWrapper}>
                    <span className={styles.popokPropertyDescriptionMini}>Each ticket contains 4 unique numbers, and matches determine the prize tier</span>
                    <span className={styles.popokPropertyDescription}>Take your chance to win a luxury apartment in Ras Al-Khaimah, UAE, by purchasing one of the 150,000 NFT tickets available for just 1 FTN each. Don’t miss out!</span>
                </div>
                <Button onClick={() => {
                    navigate("/room/869")
                }} size={"lg"} type={"rounded-12"} color={"primary"}>Enter the Raffle Space</Button>
            </div>
            <img alt={"ticketNumberImg"} src={ticketNumberImg} className={styles.popokPropertyInfoImage}/>
        </div>
    )
}

export default TicketNumberSection;