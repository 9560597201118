import styles from "./howItWorksSection.module.scss";
import cn from "classnames";
import getYourTicketsImg from "../../../../assets/images/ortak/get_your_tickets.png"
import getYourTicketsMobileImg from "../../../../assets/images/ortak/get_your_ticket_mobile.png"
import checkYourResultsImg from "../../../../assets/images/ortak/check_your_results.png"
import enterTheDrawImg from "../../../../assets/images/ortak/enter_the_draw.png"
import {isMobile} from "react-device-detect";

const HowItWorksSection = () => {
    return (
        <div className={styles.popokPropertySection}>
            <div className={cn(styles.popokPropertyInfo, styles.popokPropertyHead)}>
                <h2 className={styles.popokPropertyTitle}>How It Works</h2>
                <div className={styles.popokPropertyDescriptionContainer}>
                    <span className={styles.popokPropertyDescription}>Simple Steps to Win Big!</span>
                </div>
            </div>
            <div className={cn(styles.popokPropertyInfoContainer)}>
                <div className={styles.popokPropertyInfoWrapper}>
                    <div className={styles.popokPropertyInfo}>
                        <h2 className={styles.popokPropertyTitleMini}>Get Your Tickets</h2>
                        <div className={styles.popokPropertyDescriptionContainer}>
                            <div className={styles.popokPropertyDescriptionWrapper}>
                                <span className={styles.popokPropertyDescriptionCount}>1</span>
                                <span className={styles.popokPropertyDescriptionMini}>Through gambling websites that feature Ortak v2 solution․</span>
                            </div>
                            <div className={styles.popokPropertyDescriptionWrapper}>
                                <span className={styles.popokPropertyDescriptionCount}>2</span>
                                <span
                                    className={styles.popokPropertyDescriptionMini}>At Ortak.me white-label platforms․</span>
                            </div>
                            <div className={styles.popokPropertyDescriptionWrapper}>
                                <span className={styles.popokPropertyDescriptionCount}>3</span>
                                <span className={styles.popokPropertyDescriptionMini}>From Ortak.me marketplace directly.</span>
                            </div>
                        </div>
                    </div>
                    <img alt={"getYourTicketsImg"} src={isMobile ? getYourTicketsMobileImg : getYourTicketsImg}
                         className={styles.popokPropertyInfoImage}/>
                </div>
                <div className={styles.popokPropertyInfoWrapper}>
                    <div className={styles.popokPropertyInfo}>
                        <h2 className={styles.popokPropertyTitleMini}>Enter the Draw</h2>
                        <div className={styles.popokPropertyDescriptionContainer}>
                            <div className={styles.popokPropertyDescriptionWrapper}>
                            <span
                                className={styles.popokPropertyDescriptionMini}>Each NFT purchased gives you 1 ticket to the raffle. The number of tickets one can buy is not limited.</span>
                            </div>
                        </div>
                    </div>
                    <img alt={"enterTheDrawImg"} src={enterTheDrawImg} className={styles.popokPropertyInfoImage}/>
                </div>
                <div className={styles.popokPropertyInfoWrapper}>
                    <div className={styles.popokPropertyInfo}>
                        <h2 className={styles.popokPropertyTitleMini}>Check Your Results</h2>
                        <div className={styles.popokPropertyDescriptionContainer}>
                            <div className={styles.popokPropertyDescriptionWrapper}>
                                <span className={styles.popokPropertyDescriptionMini}>The Raffle draw takes place in the YoCerebrum environment and the results are instantly available on Ortak.me marketplace. The raffle operates on blockchain technology with a smart contract ensuring transparency. </span>
                            </div>
                        </div>
                    </div>
                    <img alt={"checkYourResultsImg"} src={checkYourResultsImg}
                         className={styles.popokPropertyInfoImage}/>
                </div>
            </div>
            <div></div>
        </div>
    )
}

export default HowItWorksSection;