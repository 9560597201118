import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import "../policies.scss";
import MetaTags from "../../MetaTags";

const PrivacyPolicy = observer(() => {
    const navigate = useNavigate();
    const openTerms = () => {
        navigate("/terms");
    };

    return <div className="policyInnerContainer container">
        <MetaTags
            title={"YoCerebrum | Privacy Policy"}
            description={"YoCerebrum, a metaverse world to create and connect. Buy, sell and rent spaces, build corporations and digital twins, attend events and shop in virtual reality"}
        />
        <h1>YOCEREBRUM PRIVACY POLICY – Platform, Software, App and Website</h1>
        <div className="policyContent">
            <p><strong>Right to Object:</strong> You have a legal right to object at any time to:</p>
            <p>(i) use of your personal information for direct marketing purposes; and</p>
            <p>(ii) processing of your personal information which is based on our legitimate interests, unless there are
                compelling legitimate grounds for our continued processing.</p>
            <p className="padding">The security of your personal information is important to us.</p>
            <p className="padding">This Privacy Policy is designed to help you feel more confident about the privacy and
                security of your personal information when accessing the Yocerebrum platform
                (the <strong>"Platform"</strong>) provided via a software as a service solution
                (the <strong>"Software"</strong>), via the Yocerebrum web app (the <strong>"App"</strong>) and / or
                using the Yocerebrum website found at <a
                    href="https://www.yocerebrum.com/">https://www.yocerebrum.com/</a> (the <strong>"Website"</strong>)
                (together, the <strong>"Services"</strong>).</p>
            <p className="padding">This Privacy Policy is designed to help users of our Services
                (<strong>"you/your"</strong>) feel more confident about the privacy and security of your information
                when using our Services and (together with our applicable <span onClick={openTerms}>Terms and Conditions</span>)
                sets out the basis on which we process any personal information we collect from you, or that you provide
                to us through your use of our Services.</p>
            <p className="padding">By using our Services, you agree to the collection and use of your personal data by
                us in accordance with this Privacy Policy. We will ensure that the information you submit to us, or
                which we collect, via various channels, is only used for the purposes set out in this Privacy Policy. If
                you do not agree to the terms of this Privacy Policy, please do not provide us with any information and
                do not use our Services.</p>
            <p className="padding">For the purpose of this Privacy Policy the controller of personal data is Soft
                Construct (Malta) Limited
                (<strong>"SoftConstruct"</strong>, <strong>"we"</strong>, <strong>"us"</strong> or <strong>"our"</strong>)
                and our contact details are set out in the Contact section of this Policy.</p>
            <h2>1. AGE REQUIREMENTS</h2>
            <p>WE DO NOT KNOWINGLY COLLECT PERSONAL INFORMATION FROM CHILDREN UNDER 13 YEARS OF AGE. IF YOU ARE UNDER 13
                YEARS OLD, PLEASE DO NOT PROVIDE ANY PERSONAL INFORMATION TO US.</p>
            <h2>2. INFORMATION WE MAY COLLECT FROM YOU</h2>
            <p>2.1 We may collect and process the following information about you:</p>
            <p>2.1.1 information that you provide to us, for example your name, email address, telephone number,
                country, city, employer and employment role as required to register on our Platform;</p>
            <p>2.1.2 information that you publish on your Platform user profile (<strong>"Profile"</strong>) and
                comments which you post on any public, private or group chats or message boards on the Platform. Please
                note that information that you voluntarily make public in your Profile, or which you disclose by posting
                on public group chats or message boards will be publicly available and viewable by other users of our
                Platform. We do not hold any liability for any information that you voluntarily choose to be public
                through such and/or other explicit actions;</p>
            <p>2.1.3 information collected should you choose to use certain integrated tools (such as Slack, MirrorFly
                and Jari) on our Platform;</p>
            <p>2.1.4 information relating to your device and operating system;</p>
            <p>2.1.5 video and audio recordings of you, should you choose to voluntarily participate in any recorded
                meetings, exhibitions, events or conferences on our Platform;</p>
            <p>2.1.6 information relating to your use of the Platform, such as exhibitions or expos attended, or
                meetings or conference halls entered;</p>
            <p>2.1.7 payment and billing information, and details of transactions, should you make any purchases on our
                Platform;</p>
            <p>2.1.8 correspondence, or a record of it, should you contact us (for example, or when you report a problem
                with our Service, or contact us in respect of this Privacy Policy);</p>
            <p>2.1.9 information which you provide to us for the purpose of subscribing to direct marketing; and</p>
            <p>2.1.10 statistical data about your browsing actions and patterns, which does not identify you.</p>
            <p>2.2 It is important that the personal information we hold about you is accurate and current. Please keep
                us informed if, at any time, your personal information changes by contacting us at <a
                    href="mailto:info@yocerebrum.com">info@yocerebrum.com</a>.</p>
            <h2>3. HOW WE USE YOUR INFORMATION</h2>
            <p>3.1 We use information held about you in the following ways:</p>
            <p>3.1.1 to provide our Services to you;</p>
            <p>3.1.2 to ensure that the Services are presented in the most effective manner for you and for your
                computer;</p>
            <p>3.1.3 to identify you when you use the Services;</p>
            <p>3.1.4 to answer your questions and improve the Services;</p>
            <p>3.1.5 to deal with enquiries or complaints made by or about you relating to our Services;</p>
            <p>3.1.6 to keep our systems secure and prevent fraud;</p>
            <p>3.1.7 where relevant, to meet legal, regulatory and compliance requirements;</p>
            <p>3.1.8 where relevant, for the establishment, exercise or defence of legal claims;</p>
            <p>3.1.9 to monitor and analyse the use of any Profile or the Services to prevent, investigate and report
                any security incidents or crime;</p>
            <p>3.1.10 to improve the functionality and features provided through the Services and for developing new or
                additional services, by using user data in an anonymised and aggregated form with the anonymised data of
                other users of the Services;</p>
            <p>3.1.11 to notify you about changes to our Services; and</p>
            <p>3.1.12 with your consent or where otherwise permitted by law, to provide you with updates on new
                features, industry news and updates, events, special offers, promotions and competitions or for
                marketing purposes.</p>
            <h2>4. The Legal Basis for Processing your Personal Information</h2>
            <p>4.1 We will only use your personal information when the law allows us to. We will use your personal
                information in the following circumstances:</p>
            <p>4.1.1 <strong>where it is necessary for entering into or performing a contract</strong> – in order to
                perform obligations which arise under the contract we have entered into with you, it will be necessary
                for us to process your personal data;</p>
            <p>4.1.2 <strong>where we need to comply with a legal obligation</strong> – we are subject to certain legal
                requirements which may require us to process your personal data. We may also be obliged by law to
                disclose your personal data to a regulatory body or law enforcement agency;</p>
            <p>4.1.3 <strong>where it is necessary for our legitimate interests (or those of a third party) and your
                interests and fundamental rights do not override those interests</strong> – either we, or a third party,
                will need to process your personal data for the purposes of our (or a third party's) legitimate
                interests, provided we have established that those interests are not overridden by your rights and
                freedoms, including your right to have your personal data protected.</p>
            <p>Our legitimate interests include: providing access and use of the Services to our users, dealing with
                user and third party enquiries, keeping our systems secure, improving the functionality of our Services,
                informing you of changes to our Services (or to provide you with updates and other information
                (including new features)), and generally ensuring that our operations are conducted in an appropriate
                and efficient manner.</p>
            <p>4.1.4 <strong>where you have provided consent</strong> – in some circumstances, we may ask for your
                consent to process your personal data in a particular way.</p>
            <h2>5. HOW WE SHARE YOUR INFORMATION</h2>
            <p>5.1 We may share your personal information with third parties in the following circumstances:</p>
            <p>5.1.1 for marketing purposes where you consent to this;</p>
            <p>5.1.2 to other members of our group of companies, our trusted partners and / or service providers located
                within the EEA for the purposes of managing or administering certain aspects of our Services; for
                analytics purposes; and to help us develop new services. These companies include:</p>
            <ul>
                <li>Companies which provide integrated tools on our Platform, such as MirrorFly, Slack, Jira;</li>
                <li>Companies that help us fulfil your payments, such as Stripe.com, Paypal.com;</li>
                <li>Professional service providers such as website hosting providers, system providers, website
                    analytics providers and advertisers who help us run our business, such as Google, Hotjar Ltd.,
                    SEMRush and Intercom;
                </li>
                <li>Direct marketing companies who help us manage our electronic communications with you;</li>
            </ul>
            <p>5.1.3 to protect the rights, property or safety of us or other Service users;</p>
            <p>5.1.4 where we are obliged, or permitted, to do so by applicable law, regulation or legal process; or</p>
            <p>5.1.5 if we (or substantially all of our assets) are acquired by a third party, in which case personal
                information held by us about our users will be one of the transferred assets.</p>
            <p>5.2 We may pass aggregated information to third parties about how our users use our Services but this
                will not include information which could be used to identify you.</p>
            <h2>6. WHERE WE STORE YOUR INFORMATION</h2>
            <p>6.1 We take appropriate organisational and technical measures to protect your personal information that
                we hold. We limit access to your personal information to those who we believe reasonably need to come
                into contact with that information in order to carry out their jobs. They will only process your
                personal data on our instructions and they are subject to a duty of confidentiality.</p>
            <p>6.2 By submitting your personal information to us, you agree to such transfer, storing or processing. We
                will take all steps reasonably necessary to ensure that your personal information is treated securely at
                all times and in accordance with this Privacy Policy.</p>
            <p>6.3 Unfortunately, the transmission of information via the internet is not completely secure. Although we
                will do our best to protect your personal information, we cannot guarantee the security of your
                information transmitted to our Services and any transmission is at your own risk. We have put in place
                procedures to deal with any suspected personal data breach and will notify you and any applicable
                regulator of a breach where we are legally required to do so.</p>
            <h2>7. How long we will hold your information</h2>
            <p>7.1 We will only retain your personal data for as long as necessary to fulfil the purposes we collected
                it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
            <p>7.2 To determine the appropriate retention period for personal data, we consider the amount, nature, and
                sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
                personal data, the purposes for which we process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal requirements.</p>
            <h2>8. INTERNATIONAL TRANSFERS OF YOUR INFORMATION</h2>
            <p>8.1 We may transfer the personal data we collect about you outside of the UK or the EEA in order to
                perform our contract with you. We have taken steps to ensure all personal data is provided with adequate
                protection and that all transfers of personal data outside the UK or the EEA are done lawfully and in
                accordance with data protection laws. Where we transfer personal data outside of the UK or the EEA to a
                country not determined by the European Commission as providing an adequate level of protection for
                personal data, the transfers will be under an agreement which covers the EU necessary requirements for
                the transfer of personal data outside the UK or the EEA, such as the European Commission approved
                standard contractual clauses.</p>
            <h2>9. COOKIES</h2>
            <p>9.1 We use cookies and similar technologies to provide and optimise our Website. You can control which
                cookies you get and which you keep on your device through your browser settings [or the cookie consent
                mechanism that will be displayed when you first arrive at our Website]. Please note that if you do not
                accept cookies, some parts of our Website may become inaccessible or not function properly and you may
                not be able to take advantage of many of the special features of the Website.</p>
            <p>9.2 All of the major browsers offer tips and guidance for managing the cookies available on your browser.
                There are also lots of different third-party browser plug ins and extensions available which you can
                download to make it easier to see and control your cookies.</p>
            <p>9.3 You can find out more about internet advertising by visiting the following websites: <a
                href="https://www.allaboutcookies.org/">www.allaboutcookies.org</a>, <a
                href="https://www.yourchoicesonline.eu/">www.yourchoicesonline.eu</a>, and <a
                href="https://www.networkadvertising.org/">www.networkadvertising.org</a>. Some of these sites enable
                you to opt out of online behavioural advertising and other tracking cookies (in addition to the control
                settings on your browser).</p>
            <p>9.4 Please click <span className="openCookies">here</span> for our full Cookie Policy.</p>
            <h2>10. LINKS</h2>
            <p>Our Website may contain links to and from other websites and services.</p>
            <p>We are not responsible for the privacy practices or the content of any third-party sites.</p>
            <p>Please check the individual privacy policy of any such websites or services before you submit any
                personal information to them.</p>
            <h2>11. ADVERTISERS</h2>
            <p>We may from time to time use third party advertisers or sponsors on our Services. In the event that we do
                so we will not disclose identifiable information about individuals, but we may provide them with
                aggregated information about our users. We may also use such aggregated information to help advertisers
                reach the kind of audience they want to target. We may make use of the personal information we have
                collected from you to enable us to comply with our advertisers' and sponsors' wishes by displaying their
                advertisement to that target audience.</p>
            <h2>12. KEEPING YOUR PROFILE SECURE</h2>
            <p>12.1 Where we have given you (or where you have chosen) a password which enables you to access certain
                parts of the Services, you are responsible for keeping this password confidential and you are
                responsible for any misuse of such information.</p>
            <p>You must change your password on a regular basis and must not share your password with anyone.</p>
            <p>12.2 You are solely responsible for the security and confidentiality of your Profile. Please ensure that
                you do not allow anyone else to access the Services in your name and log off after you have finished
                accessing your Profile.</p>
            <p>12.3 You are responsible for the accuracy of purchases and orders, and all information sent via the
                internet using your user ID, password or any other personal identification used to identify you on the
                Services.</p>
            <p>12.4 You will be liable to us for all purchases, orders or transactions placed by use of your user ID and
                password, even if such use might be wrongful. We will not be liable to you for any loss that you may
                incur as a result of misuse of your user ID and password, and we accept no liability resulting from its
                unauthorised use, whether fraudulent or otherwise.</p>
            <h2>13. MARKETING</h2>
            <p>13.1 With your permission, we may send you information about other products and services that may be of
                interest to you where:</p>
            <p>13.1.1 you have consented to this (via the opt-in box when you register for the Services or
                elsewhere);</p>
            <p>13.1.2 you have purchased a product or service from us, unless you have opted-out of receiving such
                communications; and/or</p>
            <p>13.1.3 it is in our legitimate interest to do so, for example to provide you with updates and other
                information on our activities, products and services.</p>
            <p>13.2 You can manage your marketing communication preferences by contacting us by post or email at the
                address set out below or using the methods referred to in 13.3 below.</p>
            <p>13.3 You have the right at any time to ask us not to process your personal information for marketing
                purposes. You can exercise your right to opt out of such processing by checking certain boxes on the
                forms or registration pages that we use to collect your information, clicking "unsubscribe" links on
                email communications that we send you. You can also exercise this right at any time by contacting us by
                post or email at the address set out below.</p>
            <p>13.4 Please note that even if you ask not to receive marketing communications, we may still need to send
                you service messages regarding your use of the Services.</p>
            <h2>14. RIGHTS THAT YOU HAVE</h2>
            <p>14.1 You have certain rights in relation to personal information we hold about you. Details of these
                rights and how to exercise them are set out below. We will require evidence of your identity before we
                are able to act on your request.</p>
            <p>14.1.1 <strong>Access</strong> - you have the right at any time to ask us for a copy of the personal
                information that we hold about you and to check that we are lawfully processing it. Where we have good
                reason, and if the GDPR permits, we can refuse your request for a copy of your personal information, or
                certain elements of the request. If we refuse your request or any element of it, we will provide you
                with our reasons for doing so.</p>
            <p>14.1.2 <strong>Right of Correction or Completion</strong> - if personal information we hold about you is
                not accurate or is out of date and requires amendment or correction you have a right to have the data
                rectified or completed. You can let us know by contacting us using the details set out in our Contact
                section below.</p>
            <p>14.1.3 <strong>Right of Erasure</strong> - in certain circumstances, you have the right to request that
                personal information we hold about you is erased e.g. if the information is no longer necessary for the
                purposes for which it was collected or processed or our processing of the information is based on your
                consent and there are no other legal grounds on which we may process the information.</p>
            <p>14.1.4 <strong>Right to object to or restrict processing</strong> - in certain circumstances, you have
                the right to object to our processing of your personal information by contacting us using the details
                set out in our Contact section below. For example, if we are processing your information on the basis of
                our legitimate interests and there are no compelling legitimate grounds for our processing which
                override your rights and interests. You also have the right to object to use of your personal
                information for direct marketing purposes. You may also have the right to restrict our use of your
                personal information, such as in circumstances where you have challenged the accuracy of the information
                and during the period where we are verifying its accuracy.</p>
            <p>14.1.5 <strong>Right of Data Portability</strong> - in certain instances, you have a right to receive any
                personal information that we hold about you in a structured, commonly used and machine-readable format.
                You can also ask us to transmit that information to you or directly to a third-party organisation. The
                above right exists only in respect of personal information that you have provided to us previously and
                is processed by us using automated means. While we are happy for such requests to be made, we are not
                able to guarantee technical compatibility with a third-party organisation's systems. We are also unable
                to comply with requests that relate to personal information of others without their consent.</p>
            <p>14.1.6 <strong>Right to withdraw consent</strong> - in the limited circumstances where you may have
                provided your consent to the collection, processing and transfer of your personal information for a
                specific purpose, you have the right to withdraw your consent for that specific processing at any time.
                You can do this by contacting us using the details set out in our Contact section below. Once we have
                received notification that you have withdrawn your consent, we will no longer process your information
                for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing
                so in law.</p>
        </div>
    </div>
})

export default PrivacyPolicy;