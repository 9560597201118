import styles from "./popokProperty.module.scss";
import WelcomeSection from "../popokPropertySections/welcomeSection";
import HowItWorksSection from "../popokPropertySections/howItWorksSection";
import TicketNumberSection from "../popokPropertySections/ticketNumberSection";
import WinningListSection from "../popokPropertySections/winningListSection";
import DatesSection from "../popokPropertySections/datesSection";
import JourneySection from "../popokPropertySections/journeySection";

const PopokPropertyView = () => {
    return (
        <div className={styles.popokPropertyWrapper}>
            <WelcomeSection />
            <HowItWorksSection />
            <TicketNumberSection />
            <WinningListSection />
            <DatesSection />
            <JourneySection />
        </div>
    )
}

export default PopokPropertyView;