import styles from "./winningListSection.module.scss";
import coin3 from "../../../../assets/images/ortak/fast_coin_3.png";
import coin2 from "../../../../assets/images/ortak/fast_coin_2.png";
import keys from "../../../../assets/images/ortak/ortak_key.png";

const tierList = [
    {
        title: "Tier 1",
        description: "Matching the first (green) number (drawn from 1–150,000) wins the apartment.",
        img: keys,
        matches: "1 Matches"
    },
    {
        title: "Tier 2",
        description: "Matching the second (red) number (1–500) wins 9 FTN and a free ticket for the next raffle (300 winners).",
        img: coin2,
        matches: "2 Matches"
    },
    {
        title: "Tier 3",
        description: "Matching the third (orange) number (1–30) wins 4 FTN and a free ticket for the next raffle (5,000 winners).",
        img: coin3,
        matches: "3 Matches"
    },
    {
        title: "Tier 4",
        description: "Matching the fourth (blue) number (1–4) wins a free ticket for the next raffle (37,500 winners).",
        img: coin3,
        matches: "4 Matches"
    },
]


const WinningListSection = () => {
    return (
        <div className={styles.popokPropertySection}>
            {tierList.map((item, i) => (
                <div className={styles.popokPropertyTierListItem} key={i}>
                    <div className={styles.popokPropertyTierListInfo}>
                        <div className={styles.popokPropertyTierListTitle}>{item.title}</div>
                        <div className={styles.popokPropertyTierListDescription}>{item.description}</div>
                    </div>
                    <img alt={"TierListImage"} src={item.img} className={styles.popokPropertyTierListImage}/>
                    {/*<div className={styles.popokPropertyTierListMatches}>{item.matches}</div>*/}
                </div>
            ))}
        </div>
    )
}

export default WinningListSection;