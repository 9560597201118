export const transformUserData = (data:any)=> {
    const userData = {
        public_id: data.id || "",
        username: data.username || "",
        user_balance: data.balance || "",
        email: data.email || "",
        token: data.token || "",
        avatar_url: data.readyPlayerMeAvatar || "",
        avatar_image_url: data.readyPlayerMeAvatarImage || "",
        is_guest: "False"
    }
    return JSON.stringify(userData)
}