import styles from "./journeySection.module.scss";
import cardImg from "../../../../assets/images/ortak/popok_img.jpg";

const JourneySection = () => {
    return (
        <div className={styles.popokPropertySection}>
            <div className={styles.popokPropertyInfo}>
                <h2 className={styles.popokPropertyTitle}>Your Journey Begins Here</h2>
                <div className={styles.popokPropertyDescriptionWrapper}>
                    <span className={styles.popokPropertyDescriptionMini}>Watch the draw in a stunning YoCerebrum event room. Feel the excitement as numbers are revealed in 3D environment.</span>
                    <span className={styles.popokPropertyDescription}>Be part of the YoCerebrum community, where winners celebrate in real-time.</span>
                </div>
            </div>
            <img alt={"ticketNumberImg"} src={cardImg} className={styles.popokPropertyInfoImage}/>
        </div>
    )
}

export default JourneySection;