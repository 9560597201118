import {observer} from "mobx-react-lite";
import {cardsblockStore} from "../cardsblock/cardsblock.store";
import "./openInApp.scss";
import cn from "classnames";
import {Logo} from "../../ui/logo/logo";
import React from "react";
import {useNavigate} from "react-router-dom";
import {generateDeepLink} from "../../helpers/utils";
import {globalStore} from "../../global.store";

const OpenInApp = observer(() => {
    const {openInAppData, setOpenInAppData} = cardsblockStore;
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/");
        setOpenInAppData(null);
    }

    return (
        <div className={cn("openInAppContainer", {
            "withoutImg": !!openInAppData?.previewImage
        })}>
            <i className={"icon-goBack"} onClick={goBack}/>
            {!!openInAppData?.previewImage && (
                <img
                    src={openInAppData.previewImage}
                    alt={"Loader"}
                    className="openInAppImage"
                />
            )}
            <div className={"openInAppBody"}>
                <div className={"openInAppLogo"}>
                    <Logo name='cerebrum' type='svg'/>
                </div>
                <span className={"openInAppText"}>Open application to join this space</span>
                <a className={"openInAppButton"} href={generateDeepLink(openInAppData.id, globalStore.userData.token)}>
                    Open in app
                </a>
            </div>
        </div>
    )
})

export default OpenInApp;