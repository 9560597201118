import cn from "classnames";
import styles from "./welcomeSection.module.scss";
import Button from "../../../../ui/button";

const WelcomeSection = () => {
    return (
        <div className={cn(styles.popokPropertySection, styles.popokPropertyHead)}>
            <div className={styles.popokPropertyInfo}>
                <h2 className={styles.popokPropertyTitle}>Welcome to the PopOK Property Blockchain Raffle in YoCerebrum!</h2>
                <div className={styles.popokPropertyDescriptionWrapper}>
                    <span className={styles.popokPropertyDescriptionMini}>PopOK Gaming, together with YoCerebrum and Ortak Marketplace, presents the PopOK Property Blockchain Raffle!!</span>
                    <span className={styles.popokPropertyDescription}>Take your chance to win a luxury apartment in Ras Al Khaimah, UAE, or from a pool of 42,800 additional prizes. Purchasing one or more of the 150,000 NFT tickets available for 1 FTN each, with a grand prize of a luxurious apartment in Ras Al-Khaimah, UAE.</span>
                </div>
                <Button onClick={() => {
                    window.open("https://ortak.me/")
                }} size={"lg"} type={"rounded-12"} color={"primary"}>Buy a Ticket</Button>
            </div>
            <div className={styles.popokPropertyHeadImageWrapper}>
                <div className={styles.popokPropertyHeadImage}/>
            </div>
        </div>
    )
}

export default WelcomeSection;