import styles from "./datesSection.module.scss";
import Button from "../../../../ui/button";
import ticketNumberImg from "../../../../assets/images/ortak/ticket_number_and_prize.png";
import ortakKeyImg from "../../../../assets/images/ortak/ortak_key.png";
import {useNavigate} from "react-router-dom";

const DatesSection = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.popokPropertySection}>
            <img alt={"ortakKeyImg"} src={ortakKeyImg} className={styles.popokPropertyInfoImage}/>
            <div className={styles.popokPropertyInfo}>
                <h2 className={styles.popokPropertyTitle}>First Raffle Date</h2>
                <div className={styles.popokPropertyDatesContainer}>
                    <div className={styles.popokPropertyDoubleDates}>
                        <div className={styles.popokPropertyDateWrapper}>
                            <span className={styles.dataTitle}>Ticket Sale Start</span>
                            <span className={styles.dataDescription}>10.12.2024, 21:00 GMT+4</span>
                        </div>
                        <div className={styles.popokPropertyDateWrapper}>
                            <span className={styles.dataTitle}>Ticket Sale End</span>
                            <span className={styles.dataDescription}>13.12.2024, 21:00 GMT+4</span>
                        </div>
                    </div>
                    <div className={styles.popokPropertyDateWrapper}>
                        <span className={styles.dataTitle}>Raffle draw</span>
                        <span className={styles.dataDescription}>13.12.2024, 21:00 GMT+4</span>
                    </div>
                </div>
                <Button onClick={() => {
                    navigate("/room/869")
                }} size={"lg"} type={"rounded-12"} color={"primary"}>Reserve Your Seat!</Button>
            </div>
            <img alt={"ticketNumberImg"} src={ticketNumberImg} className={styles.popokPropertyInfoImage}/>
        </div>
    )
}

export default DatesSection;